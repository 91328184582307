import paymentBC from 'assets/img/payments/bayad-center-colored.png';
import paymentECP from 'assets/img/payments/ecpay-colored.png';
import payment711 from 'assets/img/payments/seven-eleven-payment-colored.png';
import paymentVMCard from 'assets/img/payments/visa-mastercard-colored.png';
import paymentGCash from 'assets/img/payments/gcash-colored.png';
import ios from 'assets/img/app_ios.png';
import android from 'assets/img/app_android.png';

export const paymentOptions = [
  {
    id: 'nonbank',
    name: 'Bayad Center Outlets',
    logo: paymentBC,
    instructions: [
      'A PAYMENT PAGE will open with your REFERENCE NUMBER.',
      'Go to the nearest BAYAD CENTER or LBC and pay using your REFERENCE NUMBER.',
    ],
  },
  {
    id: 'multipay',
    name: 'Bayad Center Mobile App',
    logo: paymentBC,
    instructions: [
      'A REFERENCE NUMBER will be generated',
      `Use your <b>Bayad Center Mobile App</b><br/><div className="text-center"><div style="padding: 10px 5px 10px 0; display: inline-block;"><a target="_blank" href="https://itunes.apple.com/ph/app/bayad-center-mobile/id1276999601?mt=8"><img alt="Download on the App Store" src="${ios}" style="height: 35px" /></a></div><div style="padding: 10px 0 10px 5px; display: inline-block;"><a target="_blank" href="https://play.google.com/store/apps/details?id=net.bayadcenter.app&amp;pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"><img alt="Get it on Google Play" src="${android}" style="height: 35px;" /></a></div></div>`,
      'Pay using your REFERENCE NUMBER or scan QR Code',
      'You can still use Multipay account to pay using your REFERENCE NUMBER',
    ],
  },
  {
    id: 'ecpay',
    name: 'ECPay',
    logo: paymentECP,
    instructions: [
      'AN ECPAY PAGE will open with your REFERENCE NUMBER.',
      'Go to the nearest ECPAY partners and pay using your REFERENCE NUMBER.',
    ],
  },
  {
    id: 'seveneleven',
    name: '7-eleven',
    logo: payment711,
    instructions: [
      'A 7-Eleven PAGE will open with your REFERENCE NUMBER.',
      'Go to the nearest 7-eleven branches and pay using your REFERENCE NUMBER.',
    ],
  },
  {
    id: 'card',
    name: 'Visa/MasterCard',
    logo: paymentVMCard,
    instructions: [
      'A payment gateway page will open with your REFERENCE NUMBER',
    ],
  },
  {
    id: 'gcash',
    name: 'GCash',
    logo: paymentGCash,
    instructions: [
      'A REFERENCE NUMBER will be generated',
      `Use your <b>GCash App</b><br/><div className="text-center"><div style="padding: 10px 5px 10px 0; display: inline-block;"><a target="_blank" href="https://itunes.apple.com/ph/app/gcash/id520020791?mt=8"><img alt="Download on the App Store" src="${ios}" style="height: 35px" /></a></div><div style="padding: 10px 0 10px 5px; display: inline-block;"><a target="_blank" href="https://play.google.com/store/apps/details?id=com.globe.gcash.android&amp;pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"><img alt="Get it on Google Play" src="${android}" style="height: 35px;" /></a></div></div>`,
      'Pay using your REFERENCE NUMBER',
    ],
  },
];
